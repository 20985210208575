import React from "react";
import Layout from "../../components/Layout";
import ContactBanner from "../../img/outside.jpg";
import Contact from "../../img/contactthebuckhorn.svg";
import { motion } from "framer-motion";

export default () => (
	<Layout>
		<div
			className="full-width-image-container margin-top-0 mb0"
			style={{
				background: `url(${ContactBanner})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<div
				className="mx-auto relative"
				style={{
					backgroundSize: "cover",
					backgroundPosition: "top center",
					background:
						"linear-gradient(to right, rgba(0,0,0,0.6), rgba(0,0,0,0.1))",
					height: "100%",
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					padding: "100px 0",
				}}
			>
				<motion.div
					style={{ originY: 1 }}
					initial="hidden"
					animate="visible"
					variants={{
						hidden: {
							scale: 0.8,
							opacity: 0,
						},
						visible: {
							scale: 1,
							opacity: 1,
							transition: {
								delay: 0.6,
							},
						},
					}}
				>
					<div
						className="has-text-weight-bold is-size-1"
						style={{
							backgroundColor: "rgb(166, 33, 3)",
							color: "white",
							padding: "1rem",
						}}
					>
						<div
							style={{
								display: "flex",
								lineHeight: "1",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								padding: "0px",
								height: "auto",
								position: "relative",
							}}
						>
							<img
								src={Contact}
								alt="Buckhorn Supper Club"
								className="mb0 svg-logo-menu"
							/>
						</div>
					</div>
				</motion.div>
			</div>
		</div>
		<section className="section">
			<motion.div
				style={{ originY: 1 }}
				initial="hidden"
				animate="visible"
				variants={{
					hidden: {
						scale: 0.8,
						opacity: 0,
					},
					visible: {
						scale: 1,
						opacity: 1,
						transition: {
							delay: 0.8,
						},
					},
				}}
			>
				<div className="container">
					<div className="columns">
						<div
							className="column is-8 is-offset-2"
							style={{ fontSize: "1.5em", padding: "50px 0" }}
						>
							<h1 className="red" style={{ fontSize: "2em" }}>
								Thank you!
							</h1>
							<p>We will be in contact soon!</p>
						</div>
					</div>
				</div>
			</motion.div>
		</section>
	</Layout>
);
